import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ModalLayout from "@components/Modals/ModalLayout";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { getDataFile } from "@utils/file.utils";

const TableWrapper = styled("div")({
  maxHeight: 400,
  overflowX: "auto",
  width: "100%",
});

const StyledTable = styled(Table)({
  width: "100%",
  borderCollapse: "collapse",
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "4px 8px",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const FIRST_ROWS_NB = 8;

interface PreviewExcelProps {
  file: File;
  showAccordion?: boolean;
}

const PreviewExcel: React.FC<PreviewExcelProps> = ({
  file,
  showAccordion = false,
}) => {
  const intl = useIntl();
  const [data, setData] = useState<string[][] | null>(null);
  const [loadingData, loadData] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    (async () => await getDataFile(file, setData))();
  }, [file]);

  const handleShowMore = async () => {
    loadData(true);
    setTimeout(() => {
      loadData(false);
    }, 1000);

    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const TableData = () => (
    <Card sx={{ overflow: "hidden" }}>
      <TableWrapper
        sx={{
          maxWidth: showAccordion ? 550 : "100%",
        }}
      >
        <StyledTable stickyHeader>
          <TableHead>
            <TableRow>
              {data?.[0].map((header, colIndex) => (
                <StyledTableCell key={colIndex}>
                  <Typography variant="body2">{header}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.slice(1, FIRST_ROWS_NB) ?? []).map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, colIndex) => (
                  <StyledTableCell key={colIndex}>
                    <Typography variant="body2">{cell ?? ""}</Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    </Card>
  );

  const ShowMoreButton = () =>
    data && data.length > FIRST_ROWS_NB ? (
      <Button
        variant="outlined"
        onClick={handleShowMore}
        sx={{ mt: 2 }}
        disabled={loadingData}
      >
        {loadingData ? (
          <CircularProgress size={24} />
        ) : (
          <FormattedMessage id="common.showMore" />
        )}
      </Button>
    ) : null;

  if (!data) return <CircularProgress size={24} />;

  return (
    <>
      {!showAccordion ? (
        <>
          <TableData />
          <ShowMoreButton />
        </>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              <FormattedMessage id="common.preview" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableData />
            <ShowMoreButton />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Modal for showing all data */}
      <ModalLayout
        open={openModal}
        onClose={handleCloseModal}
        title={intl.formatMessage({ id: "common.preview" })}
      >
        <Box sx={{ width: "100%" }}>
          <TableWrapper>
            <StyledTable stickyHeader>
              <TableHead>
                <TableRow>
                  {data?.[0].map((header, colIndex) => (
                    <StyledTableCell key={colIndex}>
                      <Typography variant="body2">{header}</Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(data ?? []).slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, colIndex) => (
                      <StyledTableCell key={colIndex}>
                        <Typography variant="body2">{cell ?? ""}</Typography>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableWrapper>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            color="primary"
            sx={{ mt: 2 }}
          >
            <FormattedMessage id="common.back" />
          </Button>
        </Box>
      </ModalLayout>
    </>
  );
};

export default PreviewExcel;
