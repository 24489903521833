import React, { type ReactElement, useState } from "react";

import { LoadingText } from "@components/LoadingText/LoadingText";
import { MoreVertOutlined } from "@mui/icons-material";
import {
  ButtonGroup,
  type ButtonGroupProps,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";

interface IButtonGroupedProps extends ButtonGroupProps {
  options: {
    label: string;
    onClick: () => void;
    // if label is defined, related MenuItem is loading
    loadingLabel?: string;
    disabled?: boolean;
    tooltipMessage?: string;
  }[];
  icon?: ReactElement;
}

const ButtonGrouped: React.FC<IButtonGroupedProps> = ({
  options,
  icon,
  ...buttonGroupProps
}) => {
  const [open, setOpen] = useState(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        hidden
        variant="contained"
        ref={anchorRef}
        {...buttonGroupProps}
      >
        <IconButton
          disabled={buttonGroupProps.disabled}
          onClick={handleToggle}
          size="small"
        >
          {icon ? icon : <MoreVertOutlined />}
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <Tooltip
                      key={index}
                      title={
                        option.disabled && option.tooltipMessage
                          ? option.tooltipMessage
                          : ""
                      }
                      placement="left"
                      arrow
                    >
                      <span>
                        {/* Usage of <span> so that Tooltip works with disabled  */}
                        <MenuItem
                          disabled={!!option.loadingLabel || option.disabled}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!option.loadingLabel) {
                              option.onClick();
                            }
                          }}
                          style={{
                            cursor:
                              !!option.loadingLabel || option.disabled
                                ? "not-allowed"
                                : "pointer",
                          }}
                        >
                          {option.loadingLabel ? (
                            <LoadingText text={option.loadingLabel} />
                          ) : (
                            option.label
                          )}
                        </MenuItem>
                      </span>
                    </Tooltip>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonGrouped;
