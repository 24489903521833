import React, { type FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";

import { Divider, Grid } from "@mui/material";
import HighlightedFields from "@pages/ProductPage/components/ProductPageHeader/components/HighlightedFields";
import ProductAdditionalInfos from "@pages/ProductPage/components/ProductPageHeader/components/ProductAdditionalInfos";
import ProductPackaging from "@pages/ProductPage/components/ProductPageHeader/components/ProductPackaging";
import ProductPageHeaderTabs from "@pages/ProductPage/components/ProductPageHeader/components/ProductPageHeaderTabs";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { ProductPutForm, SeasonsGlobalInformations } from "@src/types";
import {
  type AssetValue,
  MODAL_ACTION_TYPES,
  type ProductFieldsConfig,
  type ProductPutFormKeys,
} from "@src/types";
import { usePutProductMutation } from "@store/api/product";
import { selectCategoryType } from "@store/category/category.selector";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { DEBOUNCE_DELAY } from "@utils/constants.utils";

import type { CategoriesDetails } from "types/api/category";
import type { ProductGetResponse } from "types/api/product";
import type { SubCategoriesDetails } from "types/api/subCategory";
import { useDebouncedCallback } from "use-debounce";

interface ProductHeaderProps {
  product: ProductGetResponse;
}

const ProductPageHeaderData: FC<ProductHeaderProps> = ({ product }) => {
  const navigate = useNavigate();

  const [updateProduct] = usePutProductMutation();

  const seasons = useAppSelector(selectSeasons);
  const categories = useAppSelector(selectCategoryType);

  const [season, setSeason] = useState<SeasonsGlobalInformations>();
  const [category, setCategory] = useState<CategoriesDetails | undefined>(
    undefined,
  );
  const [subCategory, setSubCategory] = useState<
    SubCategoriesDetails | undefined
  >(undefined);

  const formReturn = useForm<ProductPutForm>({ mode: "onBlur" });
  const {
    register,
    setValue,
    watch,
    control,
    reset,
    trigger,
    formState: { isDirty, dirtyFields },
  } = formReturn;
  const formValues = watch();

  const { actionType } = usePopupHandler();

  const productFieldsConfig: ProductFieldsConfig = {
    name: { mandatory: true },
    numberOfSkus: { mandatory: true },
    sellOutAmbition: { mandatory: true },
    benefit: { mandatory: false },
    packshots: { mandatory: true },
    geographicScope: { mandatory: false },
    newPackaging: { mandatory: false },
    level: { mandatory: true },
    status: { mandatory: true },
    elementToHighlight: { mandatory: true },
    assortmentFile: { mandatory: true },
    additionalPhotos: { mandatory: false },
    mopOwner: { mandatory: true },
    digitalOwner: { mandatory: false },
    productOwner: { mandatory: true },
    imageProductOwner: { mandatory: true },
    imageModelShotOwner: { mandatory: true },
  };

  useEffect(() => {
    categories.map((categoryItem) => {
      const subCategory = categoryItem.subCategories.find(
        (subCategoryItem) => subCategoryItem.id === product.subCategoryId,
      );
      if (subCategory) {
        setCategory(categoryItem);
        setSubCategory(subCategory);
      }
    });
    seasons.map((yearItem) => {
      yearItem.seasons.map((seasonItem) => {
        const season = seasonItem.elements.find(
          (element) => element.id === product.seasonId,
        );
        if (season) {
          setSeason(season);
        }
      });
    });
  }, [categories, seasons]);

  useEffect(() => {
    if (season) {
      if (actionType === MODAL_ACTION_TYPES.DELETE_PRODUCT) {
        const redirectWithParams = generatePath("/season/:cleanName", {
          cleanName: season.cleanName,
        });
        navigate(redirectWithParams);
      }
    }
  }, []);

  // 2. backend update request
  const onSubmit = async () => {
    // do not update an asset field if value is mandatory AND empty
    let shouldUpdate = true;
    for (const [key, value] of Object.entries(productFieldsConfig)) {
      const isEmpty = Array.isArray(formValues[key as ProductPutFormKeys])
        ? !(formValues[key as ProductPutFormKeys] as unknown as AssetValue[])
            .length
        : formValues[key as ProductPutFormKeys] === "";
      if (value.mandatory && isEmpty) {
        shouldUpdate = false;
      }
    }

    if (season && Object.keys(dirtyFields).length > 0 && shouldUpdate) {
      updateProduct({
        ...product,
        ...formValues,
        additionalPhotos: formValues.additionalPhotos?.filter(
          (additionalPhoto) => additionalPhoto instanceof File,
        ) as File[],
        packshots: formValues.packshots?.filter(
          (packshot) => packshot instanceof File,
        ) as File[],
      });
    }
  };

  const onSubmitDebounced = useDebouncedCallback(onSubmit, DEBOUNCE_DELAY);

  // 1. auto submit data when form data changes
  useEffect(() => {
    const dirtyFieldNames = Object.keys(
      dirtyFields,
    ) as (keyof ProductPutForm)[];

    if (isDirty && dirtyFieldNames.length > 0) {
      onSubmitDebounced();
    }
  }, [JSON.stringify(formValues)]);

  // 3. rebase form values on backend response
  useEffect(() => {
    reset(product);
  }, [product]);

  return (
    <form>
      <Grid container>
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HighlightedFields
            productFieldsConfig={productFieldsConfig}
            season={season}
            product={product}
            displayBenefit={subCategory?.benefit}
            setValue={setValue}
            trigger={trigger}
            register={register}
            control={control}
            watch={watch}
          />
          <ProductPackaging
            formReturn={formReturn}
            product={product}
            productFieldsConfig={productFieldsConfig}
          />
          <Divider orientation="vertical" />
          <ProductAdditionalInfos
            formReturn={formReturn}
            product={product}
            productFieldsConfig={productFieldsConfig}
            category={category}
            subCategory={subCategory}
          />
        </Grid>

        <ProductPageHeaderTabs
          formReturn={formReturn}
          product={product}
          productFieldsConfig={productFieldsConfig}
        />
      </Grid>
    </form>
  );
};

export default ProductPageHeaderData;
