import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import useFetchFileFromBuffer from "@components/FileUploader/useFetchFileFromBuffer";
import PreviewExcel from "@components/Preview/PreviewExcel";
import PreviewPopover from "@components/Preview/PreviewPopover";
import {
  ClearOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import type { SxProps } from "@mui/system";
import type { FileComponentGetDetails } from "@src/types";
import { colors } from "@theme/theme";
import {
  convertTimeZoneInDate,
  isExcel,
  isImage,
  isUpdatedWithinLastNDays,
} from "@utils/fonctions.utils";

import dayjs from "dayjs";

interface ChipFileProps {
  file: File | FileComponentGetDetails;
  index: number;
  disabled?: boolean;
  canReplaceFile?: boolean;
  onReplace: () => void;
  onDelete: (index: number) => void;
  hide?: boolean;
  sx?: SxProps;
  showPopover?: boolean;
  highlightIfRecent?: boolean;
}

const FileUploaderChip: React.FC<ChipFileProps> = ({
  file,
  index,
  disabled,
  canReplaceFile,
  onReplace,
  onDelete,
  hide,
  sx,
  showPopover,
  highlightIfRecent = false,
}) => {
  const [open, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const { fetchFile, fileData } = useFetchFileFromBuffer(file);
  const isFile = !!fileData || file instanceof File;

  const handleMouseEnterChip = async () => {
    setOpenedPopover(true);
    if (!fileData && !(file instanceof File) && isExcel(file.contentType)) {
      await fetchFile(file.name);
    }
  };

  const handleMouseLeaveChip = () => {
    setOpenedPopover(false);
  };

  const PreviewContent = () => {
    if (fileData || file instanceof File) {
      const fileToShow = (fileData ?? file) as File;
      return (
        <Box>
          <Typography sx={{ py: 2 }} variant="caption">
            <FormattedMessage
              id="product.last-update"
              values={{
                date: convertTimeZoneInDate(
                  dayjs(fileToShow.lastModified).toDate(),
                ),
              }}
            />
          </Typography>
          {isExcel(fileToShow.type) && <PreviewExcel file={fileToShow} />}
        </Box>
      );
    }
    if (isImage(file))
      return (
        <img
          alt={file.fileName}
          style={{
            height: "100%",
            width: "100%",
          }}
          src={file.url}
        />
      );
    return null;
  };

  const isWithinFileLast7Days = () => {
    if (file instanceof File || !file?.updateDate || !highlightIfRecent)
      return false;
    return (
      highlightIfRecent &&
      !isImage(file) &&
      isUpdatedWithinLastNDays(file.updateDate, 7)
    );
  };

  return (
    <>
      <Chip
        ref={popoverAnchor}
        onMouseEnter={handleMouseEnterChip}
        onMouseLeave={handleMouseLeaveChip}
        label={
          <Typography
            sx={{
              maxWidth: "300px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {file instanceof File ? file.name : file.fileName}
          </Typography>
        }
        sx={{
          border: isWithinFileLast7Days()
            ? "2px solid " + colors.warning
            : "none",
          ...sx,
        }}
        deleteIcon={
          <Stack direction="row">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                const url =
                  file instanceof File ? URL.createObjectURL(file) : file.url;
                const a = document.createElement("a");
                a.href = url;
                a.download = file.name;
                a.click();
              }}
            >
              <DownloadOutlined />
            </IconButton>

            {canReplaceFile && !disabled && !hide && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onReplace();
                }}
              >
                <EditOutlined />
              </IconButton>
            )}

            {!disabled && !canReplaceFile && !hide && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(index);
                }}
              >
                <ClearOutlined />
              </IconButton>
            )}
          </Stack>
        }
        onDelete={() => undefined}
        clickable
      />
      {showPopover && PreviewContent() && (
        <PreviewPopover
          isOpen={open}
          onMouseEnter={handleMouseEnterChip}
          onMouseLeave={handleMouseLeaveChip}
          anchorRef={popoverAnchor}
          keepOpenOnContent={isFile}
          sx={
            isFile
              ? { maxHeight: "100%", maxWidth: 550, p: 2 }
              : { maxHeight: "50vh", overflow: "hidden" }
          }
        >
          <PreviewContent />
        </PreviewPopover>
      )}
    </>
  );
};

export default FileUploaderChip;
