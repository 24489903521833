import React, { type FC } from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { FormLabel, Grid, Typography } from "@mui/material";
import type { ProductGetResponse, ProductPutForm } from "@src/types";
import { Authority, FIELD_TYPES, type ProductFieldsConfig } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";
import type { RootState } from "@store/store";

interface Props {
  productFieldsConfig: ProductFieldsConfig;
  product: ProductGetResponse;
  canUpdateProduct?: boolean;
  setValue: UseFormSetValue<ProductPutForm>;
  trigger: UseFormTrigger<ProductPutForm>;
  control: Control<ProductPutForm>;
  watch: UseFormWatch<ProductPutForm>;
  register: UseFormRegister<ProductPutForm>;
}

const OwnerField: FC<{
  productFieldsConfig: ProductFieldsConfig;
  fieldName: keyof ProductPutForm;
  product: ProductGetResponse;
  setValue: UseFormSetValue<ProductPutForm>;
  trigger: UseFormTrigger<ProductPutForm>;
  control: Control<ProductPutForm>;
  watch: UseFormWatch<ProductPutForm>;
  register: UseFormRegister<ProductPutForm>;
}> = ({
  fieldName,
  product,
  register,
  watch,
  control,
  trigger,
  setValue,
  productFieldsConfig,
}) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: `form.label.${fieldName}`,
    defaultMessage: fieldName,
  });

  const hasUpdateProductAuthority = useAppSelector((state: RootState) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);

  return (
    <Grid item display="flex" flex={1}>
      {hasUpdateProductAuthority && isSeasonOpen ? (
        <GenericFormField<ProductPutForm>
          field={{
            fieldName,
            fieldType: FIELD_TYPES.INPUT_TEXT,
            mandatory: productFieldsConfig[fieldName]?.mandatory,
            muiVariant: "standard",
            size: "small",
          }}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      ) : (
        <Grid item display="flex" flexDirection="column">
          <FormLabel>{label}</FormLabel>
          <Typography>
            {fieldName in product ? (product[fieldName] as string) : ""}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const OwnersTab: FC<Props> = ({
  productFieldsConfig,
  product,
  register,
  setValue,
  trigger,
  control,
  watch,
}) => {
  const ownersFields: (keyof ProductPutForm)[] = [
    "mopOwner",
    "digitalOwner",
    "productOwner",
    "imageProductOwner",
    "imageModelShotOwner",
  ];

  return (
    <Grid container display="flex" flexDirection="row" gap={2}>
      {ownersFields.map((fieldName) => (
        <OwnerField
          productFieldsConfig={productFieldsConfig}
          key={fieldName}
          fieldName={fieldName}
          product={product}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      ))}
    </Grid>
  );
};

export default OwnersTab;
