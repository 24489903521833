import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { Button, Grid } from "@mui/material";
import type {
  CategoriesDetails,
  ProductDuplicationForm,
  SubCategoriesDetails,
} from "@src/types";
import { FIELD_TYPES, type FieldConfig } from "@src/types";
import { useLazyGetCategoryWithAxisQuery } from "@store/api/categories";
import { useDuplicateProductMutation } from "@store/api/product";
import { useLazyGetSubCategoriesWithCategoryIdQuery } from "@store/api/subCategories";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { LevelOfActivationOption } from "@utils/constants.utils";

interface Props {
  onClose: () => void;
  id: number;
}

const getSelectFromResult = (
  data?: CategoriesDetails[] | SubCategoriesDetails[],
) => ({
  data:
    data?.map((cat) => ({
      label: cat.name,
      value: cat.id,
    })) || [],
});

const DuplicateModal: FC<Props> = ({ onClose, id }) => {
  const [duplicateProduct, { isLoading, isSuccess }] =
    useDuplicateProductMutation();

  const [getCategories, { data: categories }] = useLazyGetCategoryWithAxisQuery(
    {
      selectFromResult: ({ data }) => getSelectFromResult(data),
    },
  );
  const seasons = useAppSelector(selectSeasons);
  const [getSubCategories, { data: subCategories }] =
    useLazyGetSubCategoriesWithCategoryIdQuery({
      selectFromResult: ({ data }) => getSelectFromResult(data),
    });
  const {
    control,
    watch,
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm<ProductDuplicationForm>({
    mode: "onBlur",
    defaultValues: {
      id,
    },
  });

  const seasonsOptions = seasons.flatMap((year) =>
    year.seasons.flatMap((season) => season.elements),
  );

  const {
    levelDestination,
    seasonDestination,
    categoryDestination,
    subCategoryNameDestination,
    tag,
  } = watch();

  useEffect(() => {
    (async function fetch() {
      if (seasonDestination) {
        await getCategories({ axisId: parseInt(seasonDestination) });
      }
    })();
  }, [seasonDestination]);

  useEffect(() => {
    (async function fetch() {
      // Do not make request if searched category does not exist available category list
      const isValidCategory = categories
        ?.map((cat) => cat.value)
        .includes(categoryDestination);
      if (categoryDestination && categories && isValidCategory) {
        const { data } = await getSubCategories({
          category: categoryDestination,
        });
        if (data?.length === 1) {
          setValue("subCategoryNameDestination", data[0].name);
        }
      }
    })();
  }, [categoryDestination]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const formConfig: FieldConfig<ProductDuplicationForm>[] = [
    {
      fieldName: "seasonDestination",
      fieldType: FIELD_TYPES.SELECT_SEARCH,
      mandatory: true,
      options: seasonsOptions.map((elt) => ({
        label: elt.name,
        value: elt.id,
      })),
    },
    {
      fieldName: "levelDestination",
      fieldType: FIELD_TYPES.SELECT_SEARCH,
      mandatory: true,
      options: LevelOfActivationOption,
    },
    {
      fieldName: "categoryDestination",
      fieldType: FIELD_TYPES.SELECT_SEARCH,
      mandatory: true,
      options: categories,
    },
    {
      fieldName: "subCategoryNameDestination",
      fieldType: FIELD_TYPES.SELECT_SEARCH,
      mandatory: true,
      options: subCategories,
    },
    {
      fieldName: "tag",
      fieldType: FIELD_TYPES.BOOLEAN,
    },
  ];

  const onSubmit = async () => {
    await trigger();
    if (isValid) {
      duplicateProduct({
        id,
        seasonId: parseInt(seasonDestination),
        subCategoryId: parseInt(subCategoryNameDestination),
        level: levelDestination,
        tag,
      });
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {formConfig.map((field) => (
        <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
          <GenericFormField
            field={field}
            setValue={setValue}
            register={register}
            trigger={trigger}
            control={control}
            watch={watch}
          />
        </Grid>
      ))}

      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="common.back" />
          </Button>

          <Button
            disabled={isLoading || !isValid}
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="common.confirm.duplication" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DuplicateModal;
