import React, { type FC, type ReactElement } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { colors } from "@theme/theme";

type Props = {
  label: string;
  onClick?: () => void;
  isActive?: boolean;
  hasNestedRoutes?: boolean;
  isNestedMenuOpen?: boolean;
  icon?: ReactElement;
  isTitle?: boolean;
};

const MenuItem: FC<Props> = ({
  label,
  onClick,
  isActive = false,
  hasNestedRoutes = false,
  isNestedMenuOpen = false,
  icon,
  isTitle = false,
}) => {
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        color: "primary.contrastText",
        ...(isActive && { backgroundColor: colors.grayTransparent }),
      }}
    >
      <>
        <ListItemButton
          sx={{
            minHeight: 24,
            justifyContent: "initial",
            px: 2.5,
            pt: isTitle ? 1 : 0,
            pb: isTitle ? 1 : 0,
          }}
          {...(onClick && { onClick: onClick })}
        >
          {icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: "center",
              }}
            >
              {icon}
            </ListItemIcon>
          )}

          <ListItemText primary={label} />
          {hasNestedRoutes && isNestedMenuOpen && (
            <ExpandLess color="secondary" />
          )}
          {hasNestedRoutes && !isNestedMenuOpen && (
            <ExpandMore color="secondary" />
          )}
        </ListItemButton>
      </>
    </ListItem>
  );
};

export default MenuItem;
