import { createSlice } from "@reduxjs/toolkit";
import type { ProductGetResponse } from "@src/types";
import { productApi } from "@store/api/product";

interface ProductState {
  product?: ProductGetResponse;
}

const initialState: ProductState = {};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetProduct: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.postProduct.matchFulfilled,
      (state, { payload }) => {
        state.product = payload;
      },
    );
    builder.addMatcher(
      productApi.endpoints.getProduct.matchFulfilled,
      (state, { payload }) => {
        state.product = payload;
      },
    );
  },
});

export const { resetProduct } = productSlice.actions;

export default productSlice.reducer;
