import type { AssetField } from "@src/types";
import type { FileListAssetContent } from "@src/types";
import { selectRoles } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";

const useAssetFieldDisabled = () => {
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);
  const userRoles = useAppSelector(selectRoles);

  return (field: AssetField | FileListAssetContent) =>
    !isSeasonOpen ||
    field.editable === "NO" ||
    ("editableBy" in field &&
      field.editable === "YES" &&
      !field.editableBy.some((role) => userRoles.includes(role)));
};

export default useAssetFieldDisabled;
