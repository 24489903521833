import React, { type FC, Fragment } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import Dot from "@components/Dot/Dot";
import {
  DownloadOutlined,
  LockOpenOutlined,
  LockOutlined,
} from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useExportPPTX from "@pages/HomePage/hooks/useExportPptx";
import type {
  SeasonsGlobalInformations,
  SeasonsOrderByYears,
} from "@src/types";
import { colors } from "@theme/theme";

import { AssetTypology } from "types/api/export";

interface Props {
  season: SeasonsOrderByYears;
}

const SeasonsRows: FC<Props> = ({ season }) => {
  const intl = useIntl();
  const navigation = useNavigate();

  const handleClickSeason = (element: SeasonsGlobalInformations) => {
    const redirectWithParams = generatePath("season/:cleanName", {
      cleanName: element.cleanName,
    });
    navigation(redirectWithParams);
  };

  // it would be great to have a different request state for each request (if some are pending at the same time)
  const { exportPPTX, isLoading, loadingTypology } = useExportPPTX();

  return (
    <Grid container>
      {season.seasons.map((seasonGroup) => (
        <Grid
          container
          item
          key={seasonGroup.seasonTypeName}
          mt={2}
          display="flex"
        >
          <Grid item xs={2} mt={2.5}>
            <Typography variant="body2">
              {seasonGroup.seasonTypeName}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Stack
              direction="row"
              key={seasonGroup.seasonTypeId}
              display="flex"
              alignItems="center"
              gap={2}
              useFlexGap
              sx={{ flexWrap: "wrap" }}
            >
              {seasonGroup.elements.map((element) => (
                <Fragment key={element.id}>
                  <Card sx={{ width: 240, overflow: "visible" }}>
                    <CardActionArea onClick={() => handleClickSeason(element)}>
                      <CardContent
                        sx={{
                          p: 1,
                          height: 48,
                          display: "flex",
                        }}
                      >
                        <Grid
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          gap={2}
                        >
                          <Grid
                            item
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            height="100%"
                            gap={1}
                          >
                            <Grid item>
                              <Typography fontWeight="900">
                                {element.name.toUpperCase()}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {element.isClosed ? (
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "season.tooltip.closed",
                                  })}
                                >
                                  <LockOutlined color="error" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: "season.tooltip.opened",
                                  })}
                                >
                                  <LockOpenOutlined color="success" />
                                </Tooltip>
                              )}
                            </Grid>
                            {element.nbModifiedProducts > 0 ||
                              (element.nbModifiedOrCanceledProducts > 0 && (
                                <span>
                                  <Dot color={colors.warning} />
                                </span>
                              ))}
                          </Grid>
                          <Grid item>
                            <ButtonGrouped
                              onClick={(e) => e.stopPropagation()}
                              icon={<DownloadOutlined />}
                              options={Object.values(AssetTypology).map(
                                (item) => ({
                                  label: intl.formatMessage({
                                    id: `asset.typology.${item}`,
                                  }),
                                  onClick: () =>
                                    exportPPTX(element.cleanName, item),
                                  loadingLabel:
                                    isLoading && loadingTypology === item
                                      ? intl.formatMessage({
                                          id: `asset.typology.${item}`,
                                        })
                                      : undefined,
                                }),
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Fragment>
              ))}
            </Stack>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default SeasonsRows;
