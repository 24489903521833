const SEASONS = "seasons";
const CATEGORIES = "categories";
const PRODUCTS = "products";
const ASSETS = "assets";
const EXPORTS = "exports";
const FILES = "files";

export default {
  season: {
    all: `/${SEASONS}`,
    withCleanName: (name?: string) => `/${SEASONS}/${name}`,
    deleteMerch: (name: string) => `/${SEASONS}/${name}/merch`,
    seasonTypes: `/seasonTypes`,
    close: (name: string) => `/${SEASONS}/${name}/close`,
  },
  axis: {
    all: `/axis`,
  },
  category: {
    all: `/${CATEGORIES}`,
    withId: (id: number) => `/${CATEGORIES}/${id}`,
  },
  subCategory: {
    withCategoryId: (id?: number) => `/${CATEGORIES}/${id}/subcategories`,
    withId: (categoryId: number, subCategoryId: number) =>
      `/${CATEGORIES}/${categoryId}/subcategories/${subCategoryId}`,
  },
  product: {
    all: `/${PRODUCTS}`,
    withId: (id?: number) => `/${PRODUCTS}/${id}`,
    deletePackshot: (id: number) => `/${PRODUCTS}/${id}/packshot`,
    deleteAdditionalPhoto: (id: number) => `/${PRODUCTS}/${id}/additionalPhoto`,
    duplicate: (id: number) => `/${PRODUCTS}/${id}/duplicate`,
    cancel: (id: number) => `/${PRODUCTS}/${id}/cancellation`,
    availableNewAsset: (id: number) => `/${PRODUCTS}/${id}/availableAssets`,
  },
  asset: {
    all: `/${ASSETS}`,
    withId: (id: number) => `/${ASSETS}/${id}`,
    cancel: (id: number) => `/${ASSETS}/${id}/cancellation`,
    waitingForApproval: (id: number) => `/${ASSETS}/${id}/waitingForApproval`,
    deliver: (id: number) => `/${ASSETS}/${id}/deliver`,
    deleteFile: (id: number, fieldName: string) =>
      `/${ASSETS}/${id}/file/${fieldName}`,
  },
  export: {
    withSeasonCleanName: (seasonCleanName?: string) =>
      `/${EXPORTS}/seasonalBriefs/${seasonCleanName}`,
  },
  file: {
    withCharterFileName: (fileName: string) => `/${FILES}/charters/${fileName}`,
    withProductFileName: (id: string) => `/${FILES}/products/${id}`,
    withSeasonFileName: (id: string) => `/${FILES}/seasons/${id}`,
  },
};
