import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import GenericFormField from "@components/Inputs/GenericFormField";
import GenericSeasonModal from "@components/Modals/GenericSeasonModal";
import ModalLayout from "@components/Modals/ModalLayout";
import {
  EditOutlined,
  LockOpenOutlined,
  LockOutlined,
  PagesOutlined,
  WebAssetOutlined,
} from "@mui/icons-material";
import { Box, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import Lipstick from "@resources/images/Lipstick";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type {
  SeasonPutForm,
  SeasonPutRequest,
  SeasonResponse,
} from "@src/types";
import {
  ACCEPT_FILES_TYPES,
  Authority,
  FIELD_TYPES,
  MODAL_ACTION_TYPES,
} from "@src/types";
import {
  useDeleteMerchMutation,
  usePutSeasonMutation,
} from "@store/api/season";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import {
  selectAreWeAfterDefDate,
  selectIsSeasonOpen,
} from "@store/season/season.selector";
import { colors } from "@theme/theme";
import { DEBOUNCE_DELAY } from "@utils/constants.utils";
import { ItemType } from "@utils/data/enums/generals.enum";
import { convertTimeZoneInDate } from "@utils/fonctions.utils";

import dayjs from "dayjs";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  season: SeasonResponse;
}

const SeasonPageHeaderData: FC<Props> = ({ season }) => {
  const intl = useIntl();
  const hasUpdateSeasonAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_SEASONAL_BRIEF),
  );
  const hasUpdateSeasonDatesAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_SEASONAL_BRIEF_DATES),
  );
  const canUpdateSeasonDates =
    hasUpdateSeasonAuthority || hasUpdateSeasonDatesAuthority;
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);
  const canCloseOrReopenBrief = useAppSelector(selectAreWeAfterDefDate);

  const [updateSeason] = usePutSeasonMutation();
  const [deleteMerch] = useDeleteMerchMutation();

  const { handleOpenPopup, isPopupOpen, actionType, handleClosePopup } =
    usePopupHandler();

  const {
    control,
    register,
    watch,
    formState: { isDirty, errors },
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm<SeasonPutForm>({ mode: "onBlur" });

  const { deadlineDef, deadlineNoDef, merchAnnexes } = watch();

  const handleDeleteMerchAnnex = (index: number) => {
    if (merchAnnexes) {
      deleteMerch({
        name: season.cleanName,
        merchAnnex: merchAnnexes[index].name,
      });
    }
  };

  // 1. auto submit data when form data changes
  // 2. backend update request
  const onSubmit = async () => {
    // don't use isValid otherwise we won't be able to change Deadline def
    // if Deadline no def is invalid when we arrive at the page
    if (isDirty && !Object.keys(errors).length) {
      const formValues = getValues();
      const seasonData: SeasonPutRequest = {
        year: season.year,
        cleanName: season.cleanName,
        deadlineDef: formValues.deadlineDef.toDate(),
        deadlineNoDef: formValues.deadlineNoDef.toDate(),
        merchAnnexes: formValues.merchAnnexes?.filter(
          (merchAnnex) => merchAnnex instanceof File,
        ) as File[],
      };
      updateSeason(seasonData);
    }
  };

  const onSubmitDebounced = useDebouncedCallback(onSubmit, DEBOUNCE_DELAY);

  // 1. auto submit data when form data changes
  useEffect(() => {
    onSubmitDebounced();
  }, [deadlineDef, deadlineNoDef, merchAnnexes]);

  // 3. rebase form values on backend response and when page changes
  useEffect(() => {
    reset({
      deadlineDef: dayjs(season.deadlineDef),
      deadlineNoDef: dayjs(season.deadlineNoDef),
      merchAnnexes: season.merchAnnexes,
    });
  }, [season]);

  return (
    <>
      <Grid
        container
        m={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="auto"
        gap={2}
      >
        <Grid item display="flex">
          <Stack spacing={2}>
            <Box alignItems="center" display="flex" flexDirection="row">
              <Typography variant="h4" mr={2}>
                {season.name}
              </Typography>
              <Grid>
                {season.isClosed ? (
                  <LockOutlined color="error" />
                ) : (
                  <LockOpenOutlined color="success" />
                )}
              </Grid>
            </Box>

            {season.updateDate && (
              <Grid
                flexDirection="row"
                display="flex"
                alignItems="center"
                gap={1}
              >
                <EditOutlined width={18} />
                <Typography>
                  <FormattedMessage
                    id="season.last-update"
                    values={{
                      date: convertTimeZoneInDate(season.updateDate),
                    }}
                  />
                </Typography>
              </Grid>
            )}
            <Grid
              flexDirection="row"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <PagesOutlined width={18} />
              <GenericFormField
                field={{
                  fieldName: "merchAnnexes",
                  fieldType: FIELD_TYPES.FILE,
                  files: merchAnnexes,
                  accept: ACCEPT_FILES_TYPES.MERCH_ANNEXES,
                  label: intl.formatMessage({
                    id: "form.label.upload.merchAnnexes",
                  }),
                  limit: 5,
                  handleDeleteFile: handleDeleteMerchAnnex,
                  disabled: !isSeasonOpen || !hasUpdateSeasonAuthority,
                  hide: !hasUpdateSeasonAuthority,
                }}
                setValue={setValue}
                trigger={trigger}
                register={register}
                control={control}
                watch={watch}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item display="flex" flexDirection="row" gap={2}>
          <Grid item minWidth={200}>
            <form>
              <Grid
                item
                justifyContent="space-evenly"
                display="flex"
                flexDirection="column"
                rowGap={2}
              >
                <GenericFormField<SeasonPutForm>
                  field={{
                    fieldName: "deadlineNoDef",
                    fieldType: FIELD_TYPES.DATE,
                    label: "deadlineNoDef",
                    mandatory: true,
                    minDate: dayjs(),
                    maxDate: deadlineDef,
                    disabled: !canUpdateSeasonDates || !isSeasonOpen,
                  }}
                  setValue={setValue}
                  trigger={trigger}
                  register={register}
                  control={control}
                  watch={watch}
                />
                <GenericFormField
                  field={{
                    fieldName: "deadlineDef",
                    fieldType: FIELD_TYPES.DATE,
                    label: "deadlineDef",
                    mandatory: true,
                    minDate: deadlineNoDef,
                    disabled: !canUpdateSeasonDates || !isSeasonOpen,
                  }}
                  setValue={setValue}
                  trigger={trigger}
                  register={register}
                  control={control}
                  watch={watch}
                />
              </Grid>
            </form>
          </Grid>
          <Divider orientation="vertical" />
          <Grid container display="flex" flexDirection="row" gap={2}>
            <Grid
              item
              display="flex"
              justifyContent="space-evenly"
              flexDirection="column"
            >
              <Grid item display="flex" flexDirection="row" gap={2}>
                <Lipstick />
                <Typography>
                  <FormattedMessage
                    id="season.count.product.nb-results"
                    values={{ count: season.nbProducts }}
                  />
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row" gap={2}>
                <WebAssetOutlined />
                <Typography>
                  <FormattedMessage
                    id="season.count.asset.nb-results"
                    values={{ count: season.nbAssets }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="space-evenly"
              flexDirection="column"
            >
              <Grid item display="flex" flexDirection="row" gap={2}>
                {season.nbModifiedProducts > 0 && (
                  <Chip
                    color="warning"
                    sx={{ width: "100%" }}
                    label={intl.formatMessage(
                      { id: "season.count.product.nb-modified" },
                      { count: season.nbModifiedProducts },
                    )}
                  />
                )}
              </Grid>
              <Grid item display="flex" flexDirection="row" gap={2}>
                {season.nbModifiedAssets > 0 && (
                  <Chip
                    color="warning"
                    sx={{ width: "100%" }}
                    label={intl.formatMessage(
                      { id: "season.count.asset.nb-modified" },
                      { count: season.nbModifiedAssets },
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {season.nbPriorityAssets > 0 && (
              <Grid
                item
                display="flex"
                justifyContent="space-evenly"
                flexDirection="column"
              >
                <Grid item display="flex">
                  <Chip sx={{ visibility: "hidden" }} />
                </Grid>
                <Grid item display="flex">
                  <Chip
                    sx={{
                      width: "100%",
                      backgroundColor: colors.priority,
                      color: colors.white,
                    }}
                    label={intl.formatMessage(
                      { id: "season.count.asset.nb-top-priority" },
                      { count: season.nbPriorityAssets },
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {canCloseOrReopenBrief && (
            <Grid>
              <ButtonGrouped
                options={[
                  {
                    label: season.isClosed
                      ? intl.formatMessage({ id: "season.button.reopen" })
                      : intl.formatMessage({ id: "season.button.close" }),
                    onClick: () =>
                      handleOpenPopup(
                        season.isClosed
                          ? MODAL_ACTION_TYPES.REOPEN_BRIEF
                          : MODAL_ACTION_TYPES.CLOSE_BRIEF,
                      ),
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage(
          {
            id: `common.modal.generic-modal.actions.${actionType}`,
          },
          { itemType: ItemType.SEASON },
        )}
      >
        <GenericSeasonModal
          cleanName={season.cleanName}
          actionType={actionType}
          onClose={handleClosePopup}
        />
      </ModalLayout>
    </>
  );
};

export default SeasonPageHeaderData;
