import type { FC } from "react";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import PreviewPopover from "@components/Preview/PreviewPopover";
import { InfoOutlined } from "@mui/icons-material";
import { Chip, FormLabel, Grid, IconButton, Paper } from "@mui/material";
import type { ProductGetResponse } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { findSeasonById } from "@utils/fonctions.utils";

interface Props {
  product: ProductGetResponse;
  onClickNavigate: (seasonCleanName?: string) => void;
}

const ProductDuplicateInfo: FC<Props> = ({ product, onClickNavigate }) => {
  const popoverAnchor = useRef(null);
  const [open, setOpenedPopover] = useState(false);
  const seasons = useAppSelector(selectSeasons);

  const handleMouseEnter = async () => {
    setOpenedPopover(true);
  };

  const handleMouseLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <>
      <IconButton
        ref={popoverAnchor}
        size="small"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InfoOutlined />
      </IconButton>
      <PreviewPopover
        isOpen={open}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        anchorRef={popoverAnchor}
        keepOpenOnContent
      >
        <Paper>
          <Grid
            container
            item
            p={2}
            display="flex"
            flexDirection="column"
            gap={2}
            minWidth={200}
            justifyContent="space-between"
          >
            {product.duplicateOf && (
              <Grid item display="flex" gap={2} justifyContent="space-between">
                <FormLabel>
                  <FormattedMessage id="product.duplicatedFrom" />
                </FormLabel>
                <Chip
                  size="small"
                  label={findSeasonById(seasons, product.duplicateOf)?.name}
                  clickable
                  onClick={() =>
                    onClickNavigate(
                      findSeasonById(seasons, product.duplicateOf)?.cleanName,
                    )
                  }
                />
              </Grid>
            )}
            {!!product.duplicatedIn?.length && (
              <Grid item display="flex" gap={2} justifyContent="space-between">
                <FormLabel>
                  <FormattedMessage id="product.duplicatedIn" />
                </FormLabel>
                <Grid item gap={2}>
                  {product.duplicatedIn.map((duplicate) => (
                    <Chip
                      size="small"
                      clickable
                      label={findSeasonById(seasons, duplicate)?.name}
                      key={duplicate}
                      onClick={() =>
                        onClickNavigate(
                          findSeasonById(seasons, duplicate)?.cleanName,
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </PreviewPopover>
    </>
  );
};

export default ProductDuplicateInfo;
