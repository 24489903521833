import React, { type FC, Fragment } from "react";

import AddProductModal from "@components/Modals/AddProductModal";
import CloseModal from "@components/Modals/CloseModal";
import { MODAL_ACTION_TYPES } from "@src/types";

interface Props {
  actionType: MODAL_ACTION_TYPES;
  onClose: () => void;
  cleanName?: string;
}

const SeasonModal: FC<Props> = ({ actionType, onClose, cleanName }) => {
  switch (actionType) {
    case MODAL_ACTION_TYPES.ADD_PRODUCT: {
      return <AddProductModal />;
    }
    case MODAL_ACTION_TYPES.CLOSE_BRIEF: {
      if (cleanName) {
        return (
          <CloseModal
            onClose={onClose}
            shouldCloseTheBrief
            cleanName={cleanName}
          />
        );
      }
      return <Fragment />;
    }
    case MODAL_ACTION_TYPES.REOPEN_BRIEF: {
      if (cleanName) {
        return (
          <CloseModal
            onClose={onClose}
            shouldCloseTheBrief={false}
            cleanName={cleanName}
          />
        );
      }
      return <Fragment />;
    }
    case MODAL_ACTION_TYPES.NONE:
    default:
      return <Fragment />;
  }
};

export default SeasonModal;
