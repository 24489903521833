import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isError" && prop !== "disabled",
})<{ isError?: boolean; disabled?: boolean }>(({
  theme,
  isError,
  disabled,
}) => {
  const getBorderColor = (isHovered?: boolean) => {
    if (disabled) return theme.palette.action.disabled;
    if (isError) return theme.palette.error.main;
    if (isHovered) return theme.palette.primary.main;
    return theme.palette.secondary.light;
  };

  const transition = theme.transitions.create(["background", "border"], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  });

  return {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    cursor: disabled ? "not-allowed" : "pointer",
    padding: theme.spacing(4),
    background: theme.palette.background.default,
    border: `1px dotted ${getBorderColor()}`,
    borderRadius: theme.shape.borderRadius,
    transition: transition,

    "&:hover": {
      backgroundColor: !disabled ? theme.palette.action.hover : undefined,
      border: `1px dotted ${getBorderColor(true)}`,
      "& .hoverable-text": {
        color: !disabled && theme.palette.primary.main,
        transition: transition,
        cursor: disabled ? "not-allowed" : "pointer",
      },
    },
  };
});

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
