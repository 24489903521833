import type { FC } from "react";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AddProductModal from "@components/Modals/AddProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import { Button, Grid, Skeleton, Stack, Typography } from "@mui/material";
import ProductFilters from "@pages/SeasonPage/components/ProductsList/components/ProductFilters";
import ProductListItem from "@pages/SeasonPage/components/ProductsList/components/ProductListItem";
import type { ProductGroupedByLevel } from "@src/types";
import { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectIsSeasonOpen } from "@store/season/season.selector";

interface Props {
  products?: ProductGroupedByLevel[];
  isLoading?: boolean;
}

const ProductsList: FC<Props> = ({ products, isLoading }) => {
  const intl = useIntl();

  const [isModalOpen, setOpenModal] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<
    ProductGroupedByLevel[] | null
  >();
  const modalRef = useRef<HTMLDivElement>(null); // Reference to the modal content

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const hasCreateProductAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.CREATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid
        container
        display="flex"
        sx={{ p: 4 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <ProductFilters
            products={products}
            setFiltersProducts={setFilteredProducts}
          />
        </Grid>
        {hasCreateProductAuthority && (
          <Grid item>
            <Button
              startIcon={<AddOutlined />}
              onClick={handleOpenModal}
              variant="contained"
              disabled={!isSeasonOpen}
            >
              <FormattedMessage id="product.button.add" />
            </Button>
          </Grid>
        )}
      </Grid>
      {isLoading && (
        <Stack direction="column" gap={4} mx={4}>
          {[...Array(2)].map((_, i) => (
            <Skeleton key={i} variant="rectangular" width="100%" height={240} />
          ))}
        </Stack>
      )}
      <Stack direction="column" gap={4} mx={4}>
        {!isLoading && filteredProducts?.length === 0 && (
          <Typography>
            <FormattedMessage id="product.filters.no-results" />
          </Typography>
        )}
        {filteredProducts?.map((product) => (
          <ProductListItem product={product} key={product.level} />
        ))}
      </Stack>
      {hasCreateProductAuthority && (
        <ModalLayout
          open={isModalOpen}
          onClose={handleCloseModal}
          title={intl.formatMessage({ id: "product.modal.title" })}
          ref={modalRef}
        >
          <AddProductModal
            scrollToTop={() => {
              if (modalRef?.current) {
                modalRef.current.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          />
        </ModalLayout>
      )}
    </>
  );
};

export default ProductsList;
