import { useEffect, useState } from "react";

import type { FileComponentGetDetails } from "@src/types";
import {
  useLazyGetProductFileQuery,
  useLazyGetSeasonFileQuery,
} from "@store/api/file";

import dayjs from "dayjs";

type UseFetchFileResult = {
  fetchFile: (filePath: string) => Promise<void>;
  fileData: File | null;
  isLoading: boolean;
};

const useFetchFileFromBuffer = (
  file: FileComponentGetDetails | File,
): UseFetchFileResult => {
  const [bufferFile, setBufferFile] = useState<File | null>(null);

  const [getProductFile, { data: productData, isLoading: loadingProduct }] =
    useLazyGetProductFileQuery();
  const [getSeasonFile, { data: seasonData, isLoading: loadingSeason }] =
    useLazyGetSeasonFileQuery();

  const fetchFile = async (filePath: string) => {
    const parts = filePath.split("/");

    if (parts.length === 3) {
      const [type, id, fileName] = parts;
      if (type === "Product") {
        await getProductFile({ id, fileName });
      } else if (type === "Season") {
        await getSeasonFile({ id, fileName });
      }
    }
  };

  const createBufferFile = (data: number[]) => {
    if (!(file instanceof File)) {
      const arrayBuffer = new Uint8Array(data).buffer;
      const blob = new Blob([arrayBuffer], { type: file.contentType });
      const simulatedFile = new File([blob], file.fileName, {
        type: file.contentType,
        lastModified: dayjs(file.updateDate).valueOf(),
      });
      setBufferFile(simulatedFile);
    }
  };

  useEffect(() => {
    if (productData) {
      createBufferFile(productData.data);
    }
    if (seasonData) {
      createBufferFile(seasonData.data);
    }
  }, [productData, seasonData, file]);

  return {
    fetchFile,
    fileData: file instanceof File ? file : bufferFile,
    isLoading: loadingProduct || loadingSeason,
  };
};

export default useFetchFileFromBuffer;
