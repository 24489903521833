import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { Box, Button, FormHelperText, Grid, TextField } from "@mui/material";
import useNestedIntl from "@src/hooks/useNestedIntl";
import type { CancelRequest } from "@src/types";
import { useCancelAssetMutation } from "@store/api/asset";
import { useCancelProductMutation } from "@store/api/product";

interface Props {
  id: number;
  isAsset?: boolean;
  isCancel?: boolean;
  onClose: () => void;
}

const CancelModal: FC<Props> = ({ id, isAsset, isCancel = false, onClose }) => {
  const nestedIntl = useNestedIntl();
  const [
    cancelAsset,
    { isLoading: isLoadingAsset, isSuccess: isSuccessAsset },
  ] = useCancelAssetMutation();
  const [
    cancelProduct,
    { isLoading: isLoadingProduct, isSuccess: isSuccessProduct },
  ] = useCancelProductMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<CancelRequest>({
    mode: "onBlur",
    defaultValues: {
      whyIsCancel: "",
      isCancel,
      id,
    },
  });

  useEffect(() => {
    if (isSuccessAsset || isSuccessProduct) {
      onClose();
    }
  }, [isSuccessAsset, isSuccessProduct]);

  const onSubmit = async (formValues: CancelRequest) => {
    if (isValid) {
      if (isAsset) {
        cancelAsset(formValues);
      } else {
        cancelProduct(formValues);
      }
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {isCancel && (
        <Box my={4}>
          <TextField
            type="text"
            fullWidth
            error={!!errors.whyIsCancel}
            {...register("whyIsCancel", {
              required: nestedIntl(
                { id: "common.rules.required" },
                { fieldName: "common.reason.label" },
              ),
            })}
          />
          {errors.whyIsCancel && (
            <FormHelperText error>{errors.whyIsCancel?.message}</FormHelperText>
          )}
        </Box>
      )}
      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="common.back" />
          </Button>

          <Button
            disabled={isLoadingAsset || isLoadingProduct}
            type="submit"
            variant="contained"
          >
            {isCancel ? (
              <FormattedMessage id="common.confirm.cancel" />
            ) : (
              <FormattedMessage id="common.confirm.uncancel" />
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CancelModal;
