import { styled } from "@mui/material";

export const StyledDeleteImageBtnContainer = styled("div")({
  display: "block",
  position: "absolute",
  zIndex: 2,
  top: "2px",
  right: "2px",
});

export const StyledLeftIconBtnContainer = styled("div")({
  display: "block",
  position: "absolute",
  zIndex: 2,
  top: "2px",
  left: "2px",
});
