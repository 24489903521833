import { ProductPutRequestDto } from "@src/dto/productPutRequest.dto";
import type { CancelRequest, ProductDuplicationRequest } from "@src/types";
import { API_TAGS, METHODS } from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";
import { createFormDataProduct } from "@utils/api.utils";
import type { CommonAssetType } from "@utils/enum.utils";

import type {
  FileProductDeleteRequest,
  PostProductRequest,
  ProductGetRequest,
  ProductGetResponse,
  ProductGroupedByLevel,
  ProductPutRequest,
  ProductRequestBySeason,
} from "types/api/product";

export const productApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getProductsWithSeason: build.query<
      ProductGroupedByLevel[],
      ProductRequestBySeason
    >({
      query: (params) => ({
        url: endpoints.product.all,
        params,
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.PRODUCTS],
    }),
    getProduct: build.query<ProductGetResponse, ProductGetRequest>({
      query: ({ id }) => ({
        url: endpoints.product.withId(id),
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.PRODUCT],
    }),
    postProduct: build.mutation<ProductGetResponse, PostProductRequest>({
      query: ({ productValues }) => {
        return {
          url: endpoints.product.all,
          method: METHODS.POST,
          body: createFormDataProduct(productValues),
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS, API_TAGS.SEASON],
    }),
    putProduct: build.mutation<ProductGetResponse, ProductPutRequest>({
      query: (product) => {
        const productPutBody = new ProductPutRequestDto(product);
        return {
          url: endpoints.product.withId(product.id),
          method: METHODS.PUT,
          body: createFormDataProduct(productPutBody),
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((response) =>
            dispatch(
              productApi.util.updateQueryData(
                "getProduct",
                { id: response.data.id },
                () => response.data,
              ),
            ),
          )
          .catch(() => {});
      },
    }),
    deleteProduct: build.mutation<void, ProductGetRequest>({
      query: (arg) => {
        const { id } = arg;
        return {
          url: endpoints.product.withId(id),
          method: METHODS.DELETE,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS, API_TAGS.SEASON],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() =>
            dispatch(
              productApi.util.updateQueryData(
                "getProduct",
                { id: _payload.id },
                () => undefined,
              ),
            ),
          )
          .catch(() => {});
      },
    }),
    deletePackshotProduct: build.mutation<
      ProductGetResponse,
      FileProductDeleteRequest
    >({
      query: (arg) => {
        const { productId, name } = arg;
        return {
          url: endpoints.product.deletePackshot(productId),
          method: METHODS.DELETE,
          params: { packshot: name },
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((response) =>
            dispatch(
              productApi.util.updateQueryData(
                "getProduct",
                { id: response.data.id },
                () => response.data,
              ),
            ),
          )
          .catch(() => {});
      },
    }),

    deleteAdditionalPhotosProduct: build.mutation<
      ProductGetResponse,
      FileProductDeleteRequest
    >({
      query: (arg) => {
        const { productId, name } = arg;
        return {
          url: endpoints.product.deleteAdditionalPhoto(productId),
          method: METHODS.DELETE,
          params: { additionalPhoto: name },
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((response) =>
            dispatch(
              productApi.util.updateQueryData(
                "getProduct",
                { id: response.data.id },
                () => response.data,
              ),
            ),
          )
          .catch(() => {});
      },
    }),
    getAvailableNewAsset: build.query<CommonAssetType[], ProductGetRequest>({
      query: ({ id }) => {
        return {
          url: endpoints.product.availableNewAsset(id),
          method: METHODS.GET,
        };
      },
    }),
    duplicateProduct: build.mutation<
      ProductGetResponse,
      ProductDuplicationRequest
    >({
      query: (productReference) => {
        const { id, ...body } = productReference;
        return {
          url: endpoints.product.duplicate(id),
          method: METHODS.PUT,
          body: body,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS, API_TAGS.SEASON],
    }),
    cancelProduct: build.mutation<ProductGetResponse, CancelRequest>({
      query: (productReference) => {
        const { id, ...body } = productReference;
        return {
          url: endpoints.product.cancel(id),
          method: METHODS.PUT,
          body: body,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCTS, API_TAGS.SEASON, API_TAGS.PRODUCT],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetProductsWithSeasonQuery,
  useLazyGetProductQuery,
  usePostProductMutation,
  usePutProductMutation,
  useDeleteProductMutation,
  useDeletePackshotProductMutation,
  useDeleteAdditionalPhotosProductMutation,
  useGetAvailableNewAssetQuery,
  useDuplicateProductMutation,
  useCancelProductMutation,
} = productApi;
