import React, { forwardRef } from "react";

import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  type ModalProps,
  Typography,
} from "@mui/material";

const ModalLayout = forwardRef<HTMLDivElement, ModalProps & { title: string }>(
  ({ title, ...props }, ref) => {
    return (
      <Modal
        {...props}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            props.onClose && props.onClose(e, reason);
          }
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            py: 2,
            px: 4,
            overflow: "auto",
            maxHeight: "80%",
          }}
          ref={ref}
        >
          <Grid container rowGap={2}>
            <Grid container item display="flex" justifyContent="flex-end">
              <IconButton
                onClick={(e) =>
                  props.onClose && props.onClose(e, "escapeKeyDown")
                }
              >
                <CloseOutlined />
              </IconButton>
            </Grid>
            <Grid container item>
              <Grid item display="flex">
                <Typography variant="h4">{title}</Typography>
              </Grid>
            </Grid>
            {props.children}
          </Grid>
        </Box>
      </Modal>
    );
  },
);

ModalLayout.displayName = "ModalLayout"; // prevent error with ESLint

export default ModalLayout;
