import { useEffect, useMemo } from "react";

import type {
  ProductFilter,
  ProductFilterableValues,
  ProductGroupedByLevel,
} from "@src/types";
import { applyFilter, findFilterWithFieldName } from "@utils/filter";

const useFilters = (
  products?: ProductGroupedByLevel[],
  filters?: ProductFilter[],
  setFiltersProducts?: (
    filteredProducts: ProductGroupedByLevel[] | null,
  ) => void,
): {
  filteredProducts: ProductGroupedByLevel[] | null;
  countProduct: number;
} => {
  const filteredProducts = useMemo(() => {
    if (!products) return null;
    if (!filters || Object.values(filters).every((value) => !value)) {
      return products;
    }

    return products
      .filter((group) =>
        applyFilter(group.level, findFilterWithFieldName(filters, "level")),
      )
      .map((group) => ({
        ...group,
        categories: group.categories
          .filter((category) =>
            applyFilter(
              category.category,
              findFilterWithFieldName(filters, "category"),
            ),
          )
          .map((category) => ({
            ...category,
            products: category.products.filter((product) => {
              const productFields: (keyof ProductFilterableValues)[] = [
                "name",
                "status",
                "nbModifiedAssets",
                "newPackaging",
                "geographicScope",
                "subCategory",
              ];
              return productFields
                .map((fieldName) =>
                  applyFilter(
                    product[fieldName],
                    findFilterWithFieldName(filters, fieldName),
                  ),
                )
                .every((match) => match);
            }),
          }))
          .filter((category) => category.products.length > 0),
      }))
      .filter((group) => group.categories.length > 0);
  }, [products, filters]);

  useEffect(() => {
    if (setFiltersProducts) {
      setFiltersProducts(filteredProducts);
    }
  }, [JSON.stringify(filters)]); // compare values instead of object reference otherwise infinite rerender

  const countProduct =
    filteredProducts?.flatMap((group) =>
      group.categories.flatMap((category) => category.products),
    ).length || 0;

  return { filteredProducts, countProduct };
};

export default useFilters;
