import type {
  FileGetRequest,
  FileGetResponse,
  FileWithIdGetRequest,
  FileWithIdGetResponse,
} from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";

export const fileApi = defaultApi.injectEndpoints({
  endpoints: (build) => {
    return {
      getCharter: build.query<FileGetResponse, FileGetRequest>({
        query: ({ fileName }) => {
          return {
            url: endpoints.file.withCharterFileName(fileName),
          };
        },
      }),
      getSeasonFile: build.query<FileWithIdGetResponse, FileWithIdGetRequest>({
        query: ({ fileName, id }) => {
          return {
            url: endpoints.file.withSeasonFileName(id),
            params: { fileName },
          };
        },
      }),
      getProductFile: build.query<FileWithIdGetResponse, FileWithIdGetRequest>({
        query: ({ fileName, id }) => {
          return {
            url: endpoints.file.withProductFileName(id),
            params: { fileName },
          };
        },
      }),
    };
  },
  overrideExisting: false,
});

export const {
  useLazyGetCharterQuery,
  useLazyGetProductFileQuery,
  useLazyGetSeasonFileQuery,
} = fileApi;
