import type { ProductFilter, ProductFilterValues } from "@src/types";
import { type AssetValue, FIELD_TYPES } from "@src/types";

export const countActiveFilters = (filters: ProductFilter[]): number => {
  return filters.filter((filter) => {
    switch (filter.fieldType) {
      case FIELD_TYPES.INPUT_TEXT:
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.INPUT_NUMBER: {
        if (filter.value === undefined || filter.value === null) return false;
        return filter?.value?.trim() !== "";
      }
      case FIELD_TYPES.MULTI_SELECT: {
        return filter.value?.length > 0 ?? false;
      }
      case FIELD_TYPES.BOOLEAN: {
        return filter?.checked;
      }
      default: {
        return !(filter.value === undefined || filter.value === null);
      }
    }
  }).length;
};

export const applyFilter = (
  value: boolean | string | string[] | number,
  filter: ProductFilter,
) => {
  const filterValue = filter.value;
  const fieldType = filter.fieldType;

  switch (fieldType) {
    case FIELD_TYPES.INPUT_TEXT: {
      if (!filter.value) return true;
      if (typeof value !== "string") return false;
      const searchedTerm = (filterValue as string).toLowerCase();
      return value.toLowerCase().includes(searchedTerm);
    }

    case FIELD_TYPES.INPUT_NUMBER: {
      if (!filter.value) return true;
      return parseInt(filterValue) === value;
    }

    case FIELD_TYPES.SELECT: {
      if (!filter.value) return true;
      return filterValue === value;
    }

    case FIELD_TYPES.MULTI_SELECT: {
      if (!filter.value) return true;
      if (filterValue.length === 0) return true;
      const selectedValues = (filterValue as AssetValue[])?.map(
        (multiSelect) => multiSelect.value,
      );
      return selectedValues.includes(value as string);
    }

    case FIELD_TYPES.BOOLEAN: {
      if (filter.checked === undefined) return true;
      return Boolean(value) === filter.checked;
    }

    default:
      return true;
  }
};

export const findFilterWithFieldName = (
  filters: ProductFilter[],
  fieldName: keyof ProductFilterValues,
) => filters.find((filter) => filter.fieldName === fieldName);
