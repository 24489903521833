import { createSelector } from "@reduxjs/toolkit";

import { type RootState } from "store/store";

export const selectSeasons = ({ season }: RootState) => season.seasons;
export const selectedSeason = ({ season }: RootState) => season.selectedSeason;
export const selectAreWeBeforeNoDefDate = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    const currentDate = new Date();
    const deadlineDate = new Date(selectedSeason.deadlineNoDef);

    // current day counts
    currentDate.setHours(0, 0, 0, 0);
    deadlineDate.setHours(0, 0, 0, 0);

    return currentDate <= deadlineDate;
  },
);

export const selectAreWeAfterDefDate = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    const currentDate = new Date();
    return currentDate > new Date(selectedSeason.deadlineDef);
  },
);

export const selectIsSeasonOpen = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    return !selectedSeason.isClosed;
  },
);
