import { useState } from "react";
import type { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { MODAL_ACTION_TYPES } from "@src/types";
import { addSnackbar } from "@store/common/common.reducer";
import { useAppSelector } from "@store/hooks";
import { selectAreWeBeforeNoDefDate } from "@store/season/season.selector";

const usePopupHandler = () => {
  const intl = useIntl();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [actionType, setActionType] = useState<MODAL_ACTION_TYPES>(
    MODAL_ACTION_TYPES.NONE,
  );
  const [defaultValues, setDefaultValues] = useState<FieldValues>({});
  const dispatch = useDispatch();
  const isProductOrAssetDeletable = useAppSelector(selectAreWeBeforeNoDefDate);

  const handleOpenPopup = (
    action: MODAL_ACTION_TYPES,
    values: FieldValues = {},
  ) => {
    // security condition if button is not disabled but should never be able to click on delete in this case
    if (
      [
        MODAL_ACTION_TYPES.DELETE_PRODUCT,
        MODAL_ACTION_TYPES.DELETE_ASSET,
      ].includes(action) &&
      !isProductOrAssetDeletable
    ) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({ id: "warning.delete.asset.date" }),
          severity: "warning",
        }),
      );
    } else {
      setActionType(action);
      setDefaultValues(values);
      setPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setDefaultValues({});
  };

  return {
    defaultValues,
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
  };
};

export default usePopupHandler;
