import { SeasonPutRequestDto } from "@src/dto/seasonPutRequest.dto";
import { createFormDataSeason } from "@utils/api.utils";

import { defaultApi } from "store/api/api";
import endpoints from "store/api/endpoints";
import type {
  CloseSeasonPutRequest,
  FileSeasonDeleteRequest,
  SeasonPostRequest,
  SeasonPutRequest,
  SeasonRequest,
  SeasonResponse,
  SeasonsOrderByYears,
  SeasonsType,
} from "types";
import { API_TAGS, METHODS } from "types";

export const seasonApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getSeasons: build.query<SeasonsOrderByYears[], void>({
      query: () => ({
        url: endpoints.season.all,
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.SEASONS],
    }),
    getSeasonsType: build.query<SeasonsType[], void>({
      query: () => ({
        url: endpoints.season.seasonTypes,
      }),
      keepUnusedDataFor: 600,
    }),
    getSeason: build.query<SeasonResponse, SeasonRequest>({
      query: ({ cleanName }) => ({
        url: endpoints.season.withCleanName(cleanName),
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.SEASON],
    }),
    postSeason: build.mutation<SeasonsOrderByYears[], SeasonPostRequest>({
      query: (season) => {
        return {
          url: endpoints.season.all,
          method: METHODS.POST,
          body: season,
        };
      },
      invalidatesTags: [API_TAGS.SEASONS],
    }),
    putSeason: build.mutation<SeasonResponse, SeasonPutRequest>({
      query: (seasonPutPayload) => {
        const seasonPutBody = new SeasonPutRequestDto(seasonPutPayload);
        return {
          url: endpoints.season.withCleanName(seasonPutPayload.cleanName),
          method: METHODS.PUT,
          body: createFormDataSeason(seasonPutBody),
        };
      },
      invalidatesTags: [API_TAGS.SEASONS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            seasonApi.util.updateQueryData(
              "getSeason",
              { cleanName: response.data.cleanName },
              () => response.data,
            ),
          );
          // update selected season also in redux
          dispatch({
            type: "season/updateSelectedSeason",
            payload: response.data,
          });
        });
      },
    }),
    deleteMerch: build.mutation<SeasonResponse, FileSeasonDeleteRequest>({
      query: (arg) => {
        const { merchAnnex, name } = arg;
        return {
          url: endpoints.season.deleteMerch(name),
          method: METHODS.DELETE,
          params: { merchAnnex },
        };
      },
      invalidatesTags: [API_TAGS.SEASONS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            seasonApi.util.updateQueryData(
              "getSeason",
              { cleanName: response.data.cleanName },
              () => response.data,
            ),
          );
          // update selected season also in redux
          dispatch({
            type: "season/updateSelectedSeason",
            payload: response.data,
          });
        });
      },
    }),
    closeSeason: build.mutation<SeasonResponse, CloseSeasonPutRequest>({
      query: (seasonPutPayload) => {
        const { cleanName, ...body } = seasonPutPayload;
        return {
          url: endpoints.season.close(cleanName),
          method: METHODS.PUT,
          body: body,
        };
      },
      invalidatesTags: [API_TAGS.SEASONS],
      onQueryStarted: (_payload, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            seasonApi.util.updateQueryData(
              "getSeason",
              { cleanName: response.data.cleanName },
              () => response.data,
            ),
          );
          // update selected season also in redux
          dispatch({
            type: "season/updateSelectedSeason",
            payload: response.data,
          });
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetSeasonsQuery,
  useGetSeasonsTypeQuery,
  useLazyGetSeasonQuery,
  usePostSeasonMutation,
  usePutSeasonMutation,
  useDeleteMerchMutation,
  useCloseSeasonMutation,
} = seasonApi;
