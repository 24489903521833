import React, { type FC, Fragment, useState } from "react";
import { useIntl } from "react-intl";

import {
  CloseFullscreenOutlined,
  ExpandLess,
  ExpandMore,
  OpenInFull,
} from "@mui/icons-material";
import {
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ProductCard from "@pages/SeasonPage/components/ProductsList/components/ProductCard";
import type { Category, ProductGroupedByLevel } from "@src/types";
import { colors } from "@theme/theme";

interface Props {
  product: ProductGroupedByLevel;
}

const ProductsListItem: FC<Props> = ({ product }) => {
  const intl = useIntl();
  const [isOpenLevel, setIsOpenLevel] = useState(true);
  const [openedCategories, setOpenedCategories] = useState<string[]>(
    product.categories.map((category) => category.category),
  );

  const handleToggleCategory = (category: Category) => {
    if (openedCategories.includes(category.category)) {
      setOpenedCategories((prevState) =>
        prevState.filter((cat) => cat !== category.category),
      );
    } else {
      setOpenedCategories((prevState) => [...prevState, category.category]);
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Grid item gap={2} display="flex" alignItems="center">
          <Grid item>
            <Typography variant="h5">{product.level}</Typography>
          </Grid>
          <Grid item>
            <Chip
              label={intl.formatMessage(
                { id: "product.count.nb-results" },
                { count: product.nbProducts },
              )}
            />
          </Grid>
          {product.nbModifiedProducts > 0 && (
            <Chip
              color="warning"
              label={intl.formatMessage(
                { id: "product.count.nb-modified" },
                { count: product.nbModifiedProducts },
              )}
            />
          )}
          {product.nbPriorityAssets > 0 && (
            <Chip
              sx={{
                backgroundColor: colors.priority,
                color: colors.white,
              }}
              label={intl.formatMessage(
                { id: "asset.top-priority-assets.nb" },
                { count: product.nbPriorityAssets },
              )}
            />
          )}
        </Grid>
        <Grid item gap={2} display="flex" flexDirection="row">
          <Grid item>
            <IconButton
              onClick={() =>
                setOpenedCategories(
                  product.categories.map((category) => category.category),
                )
              }
            >
              <OpenInFull />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setOpenedCategories([])}>
              <CloseFullscreenOutlined />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setIsOpenLevel((prevState) => !prevState)}
            >
              {isOpenLevel ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpenLevel} timeout="auto" unmountOnExit sx={{ m: 2 }}>
        {product.categories.map((category) => (
          <Fragment key={category.category}>
            <Grid
              container
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap={2}
            >
              <Grid item>
                <IconButton onClick={() => handleToggleCategory(category)}>
                  {openedCategories.includes(category.category) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{category.category}</Typography>
              </Grid>
            </Grid>
            <Collapse
              in={openedCategories.includes(category.category)}
              timeout="auto"
              unmountOnExit
              sx={{ m: 2 }}
            >
              <Grid container spacing={2} sx={{ flexWrap: "wrap" }}>
                {category.products.map((productPreview) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    xl={4}
                    key={productPreview.id}
                  >
                    <ProductCard productPreview={productPreview} />
                  </Grid>
                ))}
              </Grid>
            </Collapse>
          </Fragment>
        ))}
      </Collapse>
    </Paper>
  );
};

export default ProductsListItem;
