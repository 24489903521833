export type FileComponentGetDetails = {
  name: string;
  contentType: string;
  fileName: string;
  url: string;
  updateDate: Date;
};

export type FileGetResponse = {
  url: string;
};

export type FileGetRequest = {
  fileName: string;
};

export type FileWithIdGetRequest = FileGetRequest & {
  id: string;
};

export type FileWithIdGetResponse = {
  type: "Buffer";
  data: number[];
};

export enum FileUploaderPreviewMode {
  POPOVER,
  INLINE,
}
