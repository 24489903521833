import React, { type FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AddSeasonModal from "@components/Modals/AddSeasonModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Box, Button, Paper, Skeleton, Typography } from "@mui/material";
import SeasonsRows from "@pages/HomePage/components/SeasonsRows";
import LogoSephoraCollection from "@resources/images/LogoSephoraCollection";
import { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";

const HomePage: FC = () => {
  const intl = useIntl();
  const [isModalOpen, setModalOpen] = useState(false);

  const seasonList = useAppSelector(selectSeasons);

  const hasCreateSeasonalBriefAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.CREATE_SEASONAL_BRIEF),
  );

  const handleOpenModal = (open: boolean) => {
    setModalOpen(open);
  };

  return (
    <Box>
      <Box sx={{ width: 400, margin: "auto" }}>
        <LogoSephoraCollection />
      </Box>

      <Paper sx={{ p: 2 }}>
        {hasCreateSeasonalBriefAuthority && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => handleOpenModal(true)}
              variant="contained"
              startIcon={<AddOutlined />}
            >
              <FormattedMessage id="season.button.add" />
            </Button>
          </Box>
        )}
        {seasonList && seasonList.length > 0 ? (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {seasonList.map((season) => (
              <TimelineItem key={season.year}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box
                    key={season.year}
                    sx={{
                      backgroundColor: "background.default",
                      borderRadius: "8px",
                      p: 2,
                    }}
                  >
                    <Typography variant="h6">{season.year}</Typography>
                    <SeasonsRows season={season} />
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        ) : (
          [...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={200}
              sx={{ my: 1 }}
            />
          ))
        )}
      </Paper>

      {hasCreateSeasonalBriefAuthority && (
        <ModalLayout
          open={isModalOpen}
          onClose={() => handleOpenModal(false)}
          title={intl.formatMessage({ id: "season.modal.title" })}
        >
          <AddSeasonModal onClose={() => handleOpenModal(false)} />
        </ModalLayout>
      )}
    </Box>
  );
};

export default HomePage;
