import React from "react";
import { FormattedMessage } from "react-intl";

import { Box, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface BooleanToggleProps {
  label: string;
  value?: boolean;
  onChange: (value?: boolean) => void;
  disabled?: boolean;
}

const BooleanToggleButton: React.FC<BooleanToggleProps> = ({
  label,
  value,
  onChange,
  disabled = false,
}) => (
  <Box display="flex" flexDirection="column">
    {label && (
      <FormLabel sx={{ color: disabled ? "action.disabled" : "text.primary" }}>
        {label}
      </FormLabel>
    )}
    <ToggleButtonGroup
      value={String(value)}
      exclusive
      onChange={(_, newValue) =>
        onChange(
          newValue === "true" ? true : newValue === "false" ? false : undefined,
        )
      }
      aria-label={label}
      disabled={disabled}
    >
      {["true", "false", "undefined"].map((val, index) => (
        <ToggleButton key={val} value={val} sx={{ width: 60 }} aria-label={val}>
          <FormattedMessage
            id={`form.toggle.${["yes", "no", "none"][index]}`}
          />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  </Box>
);

export default BooleanToggleButton;
