import React, { type FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { useCloseSeasonMutation } from "@store/api/season";

interface Props {
  onClose: () => void;
  cleanName: string;
  shouldCloseTheBrief?: boolean;
}

const CloseModal: FC<Props> = ({
  onClose,
  cleanName,
  shouldCloseTheBrief = false,
}) => {
  const [
    closeSeason,
    { isSuccess: isSuccessSeason, isLoading: isLoadingSeason },
  ] = useCloseSeasonMutation();

  useEffect(() => {
    if (isSuccessSeason) {
      onClose();
    }
  }, [isSuccessSeason]);

  const handleClick = () => {
    closeSeason({ isClosed: shouldCloseTheBrief, cleanName });
  };

  return (
    <Grid container my={2}>
      <Typography>
        {shouldCloseTheBrief ? (
          <FormattedMessage id="season.modal.desc.close.label" />
        ) : (
          <FormattedMessage id="season.modal.desc.re-open.label" />
        )}
      </Typography>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        width="100%"
        mt={4}
      >
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage id="common.back" />
        </Button>

        <Button
          disabled={isSuccessSeason || isLoadingSeason}
          type="submit"
          variant="contained"
          onClick={handleClick}
        >
          <FormattedMessage id="common.confirm" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CloseModal;
