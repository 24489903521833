import type { ReactNode} from "react";
import React, { type FC, Fragment, useState } from "react";

import CarouselImage from "@components/Carousel/CarouselImage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, FormLabel, IconButton, MobileStepper } from "@mui/material";
import type {
  AssetFileValueResponse,
  FileComponentGetDetails,
} from "@src/types";

export interface CarouselProps {
  mandatory?: boolean;
  title?: string;
  images?:
    | string[]
    | File[]
    | FileComponentGetDetails[]
    | AssetFileValueResponse[]
    | (File | FileComponentGetDetails)[];
  nbElementsPerView?: number;
  disabled?: boolean;
  handleClickDelete?: (index: number) => void;
  highlightImages?: boolean;
  leftIcon?: ReactNode;
}

const Carousel: FC<CarouselProps> = ({
  title,
  images = [],
  disabled,
  handleClickDelete,
  nbElementsPerView = 1,
  mandatory,
  highlightImages = false,
  leftIcon,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = Math.ceil(images.length / nbElementsPerView);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + nbElementsPerView);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - nbElementsPerView);
  };

  const removeImage = (index: number) => {
    if (activeStep >= images.length - 1 && activeStep > 0) {
      handleBack();
    }
    if (handleClickDelete) {
      handleClickDelete(index);
    }
  };

  if (images.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {title && <FormLabel>{title}</FormLabel>}
      <Box
        sx={{
          minWidth: 180 * Math.min(nbElementsPerView, images.length),
          p: 0,
          display: "flex",
          flex: Math.min(nbElementsPerView, images.length),
          gap: 2,
        }}
      >
        {Array.from(Array(nbElementsPerView)).map((_elt, index) => (
          <Fragment key={index}>
            <CarouselImage
              image={images[activeStep + index]}
              disabled={disabled}
              disableIcon={mandatory && images.length === 1}
              removeImage={() => removeImage(activeStep + index)}
              leftIcon={leftIcon}
              highlightImage={highlightImages}
            />
          </Fragment>
        ))}
      </Box>
      {images.length > nbElementsPerView && (
        <MobileStepper
          backButton={
            <IconButton
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>
          }
          position="static"
          activeStep={activeStep / nbElementsPerView}
          variant="dots"
          nextButton={
            <IconButton
              size="small"
              onClick={handleNext}
              disabled={activeStep >= images.length - nbElementsPerView}
            >
              <KeyboardArrowRight />
            </IconButton>
          }
          steps={maxSteps}
        />
      )}
    </Box>
  );
};

export default Carousel;
