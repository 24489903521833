import React, { type FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import GeographicScopeComponent from "@components/DataDisplay/GeographicScope";
import Dot from "@components/Dot/Dot";
import ProductActions from "@components/ProductActions";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import type { ProductPreviewDetails } from "@src/types";
import { MODAL_ACTION_TYPES } from "@src/types";
import { colors } from "@theme/theme";
import { convertTimeZoneInDate } from "@utils/fonctions.utils";

interface Props {
  productPreview: ProductPreviewDetails;
}

const ProductChip: FC<{ label: string; color?: string }> = ({
  label,
  color,
}) => (
  <Chip
    label={label}
    size="small"
    sx={color ? { backgroundColor: color, color: colors.white } : undefined}
  />
);

const PRODUCT_CARD_HEIGHT = 180;

const ProductCard: FC<Props> = ({ productPreview }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickViewDetails = () =>
    navigate(`/product/${productPreview.id}`);

  const renderChips = () => {
    const baseChips = [
      {
        label: intl.formatMessage(
          { id: "product.count.nb-skus" },
          { count: productPreview.numberOfSkus || 0 },
        ),
      },
      {
        label: intl.formatMessage(
          { id: "product.count.nb-assets" },
          { count: productPreview.numberOfAssets || 0 },
        ),
      },
    ];

    const priorityChip = productPreview.nbPriorityAssets
      ? [
          {
            label: intl.formatMessage(
              { id: "asset.top-priority-assets.nb" },
              { count: productPreview.nbPriorityAssets },
            ),
            color: colors.priority,
          },
        ]
      : [];

    const additionalChips = [
      productPreview.newPackaging && {
        label: intl.formatMessage({ id: "form.label.newPackaging" }),
      },
      productPreview.isCancel && {
        label: intl.formatMessage({ id: "product.status.cancelled" }),
        color: theme.palette.error.main,
      },
    ].filter(Boolean);

    return [...baseChips, ...priorityChip, ...additionalChips].map(
      (chip, index) => chip && <ProductChip key={index} {...chip} />,
    );
  };

  return (
    <Card
      key={productPreview.id}
      sx={{
        maxHeight: PRODUCT_CARD_HEIGHT,
        height: "100%",
        backgroundColor: productPreview.isCancel
          ? theme.palette.action.disabledBackground
          : "auto",
        overflow: "visible", // do not use overflow property to hide otherwise we lose the dynamic placement of the 3 dots popper
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardActionArea
        disableTouchRipple // disable touchable effect which has side effects with the product actions button
        onClick={handleClickViewDetails}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          position="relative"
          sx={{
            maxHeight: PRODUCT_CARD_HEIGHT,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: 1,
          }}
        >
          <CardMedia
            component="img"
            image={productPreview.packshots[0]?.url}
            alt={productPreview.name}
            sx={{
              maxHeight: PRODUCT_CARD_HEIGHT,
              width: "100%",
              objectFit: "contain",
            }}
          />
          {productPreview.geographicScope && (
            <Box position="absolute" top={8} left={8}>
              <GeographicScopeComponent
                scope={productPreview.geographicScope}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            height: "100%",
          }}
        >
          <CardContent
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              flex: 2,
              "&:last-child": {
                padding: 2,
                paddingBottom: 1,
              },
            }}
          >
            <Grid container flexDirection="column" spacing={0.5} flex={1}>
              <Grid item display="flex" alignItems="center">
                <Typography
                  variant="subtitle1"
                  component="div"
                  fontWeight="fontWeightBold"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: 1.2,
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    color: productPreview.isCancel
                      ? theme.palette.action.disabled
                      : "inherit",
                  }}
                >
                  {productPreview.name.toUpperCase()}
                </Typography>
                {!productPreview.isCancel &&
                  productPreview.nbModifiedAssets > 0 && (
                    <Box sx={{ width: 15, height: 15 }}>
                      <Dot color={colors.warning} />
                    </Box>
                  )}
              </Grid>

              <Grid item>
                <Stack direction="row" gap={0.5} sx={{ flexWrap: "wrap" }}>
                  {renderChips()}
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {productPreview.lastUpdate && (
                <Grid item>
                  <Typography variant="caption">
                    <FormattedMessage
                      id="product.last-update"
                      values={{
                        date: convertTimeZoneInDate(productPreview.lastUpdate),
                      }}
                    />
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                onClick={(e) => e.stopPropagation()} // prevent opening product details
              >
                <ProductActions
                  id={productPreview.id}
                  actions={[
                    { action: MODAL_ACTION_TYPES.DUPLICATE_PRODUCT },
                    {
                      action: productPreview.isCancel
                        ? MODAL_ACTION_TYPES.UNDO_CANCEL
                        : MODAL_ACTION_TYPES.CANCEL,
                    },
                    { action: MODAL_ACTION_TYPES.DELETE_PRODUCT },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default ProductCard;
