import type { FC } from "react";
import React, { useRef, useState } from "react";
import type { UseFormSetValue } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { AddOutlined } from "@mui/icons-material";
import {
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import type { AssetTag } from "@src/types";
import { AssetContentFieldStatus } from "@src/types";
import { colors } from "@theme/theme";
import { AssetTagToColorMapping } from "@utils/fonctions.utils";

import type { AssetForm } from "types/api/asset";

interface IProductsElementListProps {
  options?: string[];
  formValues: AssetForm;
  setValue: UseFormSetValue<AssetForm>;
  disabled?: boolean;
}

const TagsSelector: FC<IProductsElementListProps> = ({
  options,
  formValues,
  setValue,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleAddTag = (value: AssetTag) => {
    setValue(
      "new_tag.value",
      [
        ...formValues.new_tag.value,
        {
          value,
          status: AssetContentFieldStatus.NULL,
        },
      ],
      { shouldDirty: true },
    );
  };

  const isTagSelected = (tag: AssetTag) =>
    formValues.new_tag.value.map(({ value }) => value).includes(tag);

  const [selected, notSelected] = options?.reduce(
    ([p, f], value) =>
      isTagSelected(value as AssetTag)
        ? [[...p, value as AssetTag], f]
        : [p, [...f, value as AssetTag]],
    [[], []] as [AssetTag[], AssetTag[]],
  ) || [[], []];

  return (
    <Grid display="flex" alignItems="center" direction="row" gap={2}>
      {!!selected.length && (
        <Stack direction="row" gap={2}>
          {selected.map((tag: AssetTag) => (
            <Chip
              disabled={disabled}
              key={tag}
              size="small"
              variant="filled"
              sx={{
                backgroundColor: AssetTagToColorMapping[tag],
                color:
                  AssetTagToColorMapping[tag] === colors.yellow
                    ? colors.primary
                    : colors.secondary,
              }}
              onDelete={() =>
                setValue(
                  "new_tag.value",
                  formValues.new_tag.value.filter(
                    (value) => value.value !== tag,
                  ),
                  { shouldDirty: true },
                )
              }
              label={tag}
            />
          ))}
        </Stack>
      )}
      {!disabled && (
        <Grid ref={anchorRef} width="fit-content">
          <Button
            onClick={handleToggle}
            variant="outlined"
            color="secondary"
            size="small"
            disabled={notSelected.length === 0}
            startIcon={<AddOutlined />}
          >
            <FormattedMessage id="asset.tag.add" />
          </Button>
        </Grid>
      )}

      <Popper
        sx={{ zIndex: 1201 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {notSelected.map((option: AssetTag) => (
                    <MenuItem key={option} onClick={() => handleAddTag(option)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default TagsSelector;
